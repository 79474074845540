import React, { useEffect, useState } from 'react';

import ArticleFlow from 'components/contentLayouts/ArticleFlow';
import PropTypes from 'prop-types';
import { Search } from 'libs/Algolia-v2';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { transformArticleCard } from 'utils/dataTransformers/article';
import transformButton from 'libs/wordpress/utils/transformButton';
import { useSelector } from 'react-redux';

// Pure CMS data transformation before we send it to the "real" block!
const ArticleFlowBlock = ({ content = {} }) => {
    const { articles_selection: articlesSelection, button, heading, heading_size: headingSize } = content;
    const articlesData = articlesSelection[0];
    const appID = useSelector(state => state.application.applicationId);

    // Transform color data

    // Result from Algolia search
    const [articles, setArticles] = useState(null);

    // Algolia filter search which returns an array with articles
    useEffect(() => {
        let filters = null;

        switch (articlesData.layout) {
            case 'manual':
                filters = articlesData.articles.map(item => {
                    const id = item.article.article;
                    return `id = ${id}`;
                });
                break;
            case 'category':
                filters = [`categories_ids: ${articlesData.Category}`];
                break;
            default:
                break;
        }

        const allFilters = [
            // Add application id to get the correct market
            [`application_id: ${appID}`],
            filters,
        ];

        if (filters) {
            Search(`articles`, '', {
                filters: allFilters,
                length: 6,
                offset: 0,
            }).then(response => {
                const result = response?.results?.[0] || [];
                if (result) {
                    const transformedArticles = result.hits.map(article => transformArticleCard(article));

                    // Save articles
                    setArticles(transformedArticles);
                }
            });
        }
    }, [articlesData]);

    if (!articles) {
        return null;
    }

    return (
        <ArticleFlow
            articles={articles}
            button={button ? transformButton(button) : null}
            heading={heading ? decodeHTMLEntities(heading.text) : null}
            headingSize={headingSize}
            headingTag={heading ? heading.type : null}
        />
    );
};

ArticleFlowBlock.propTypes = {
    content: PropTypes.shape({
        'articles_selection': PropTypes.arrayOf(PropTypes.object),
        heading: PropTypes.object,
        'heading_size': PropTypes.string,
        buttons: PropTypes.array,
    }).isRequired,
};

export default ArticleFlowBlock;
